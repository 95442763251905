import { render, staticRenderFns } from "./duty_arrange.vue?vue&type=template&id=7868ba01&scoped=true&"
import script from "./duty_arrange.vue?vue&type=script&lang=js&"
export * from "./duty_arrange.vue?vue&type=script&lang=js&"
import style0 from "./duty_arrange.vue?vue&type=style&index=0&id=7868ba01&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7868ba01",
  null
  
)

export default component.exports